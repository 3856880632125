<template>
  <div class="timer-wrap">
    <div class="timer-content">
      <span>{{ formattedTime.project }}</span>
    </div>
    <span class="timer-total">
      Total today: {{ formattedTime.total }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Timer',
  props: {
    getTimerInfo: {
      type: Object,
      default: (() => {}),
    },
    timerStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ranges: [{
        start: 0,
        end: 0,
      }],
      timer: null,
      timerWorking: false,
    };
  },
  computed: {
    elapsedTime() {
      const self = this;
      const reduce = (startTime) => self.ranges.reduce((acum, item) => {
        // eslint-disable-next-line no-param-reassign
        acum += item.end - item.start;

        return acum;
      }, startTime);

      return {
        project: reduce(this.getTimerInfo.project),
        total: reduce(this.getTimerInfo.total),
      };
    },
    formattedTime() {
      const self = this;

      const format = (time) => {
        const dt = self.$moment(time).utc();

        return dt.format('HH:mm:ss');
      };

      return {
        project: format(this.elapsedTime.project),
        total: format(this.elapsedTime.total),
      };
    },
  },
  watch: {
    getTimerInfo() {
      this.ranges = [{
        start: 0,
        end: 0,
      }];
    },
    timerStatus(val) {
      if (val) {
        this.start();
      } else {
        this.pause();
      }
    },
  },
  beforeMount() {
    this.timer = setInterval(this.tick, 500);
  },
  beforeDestroy() {
    if (!this.timer) {
      return;
    }

    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    tick() {
      if (!this.timerWorking || this.ranges.length === 0) {
        return;
      }

      this.ranges[this.ranges.length - 1].end = Date.now();
    },
    start() {
      const now = Date.now();
      this.ranges.push({
        start: now,
        end: now,
      });
      this.timerWorking = true;
    },
    pause() {
      this.timerWorking = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .timer-wrap {
    margin-left: 15px;
    .timer-total {
      color: $grey;
      font-size: 12px;
    }
    .timer-content {
      color: white;
      font-weight: bold;
      font-size: 24px;
      display: flex;

      span {
        font-size: 24px;
        color: white;
        font-weight: 500;
      }

      .divider {
        margin: 0 2px;
      }
    }

    button {
      z-index: 10;
    }
  }
</style>
