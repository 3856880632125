<template>
  <v-app>
    <div v-show="loader" class="loader">
      <img src="@/static/preloader.gif" alt="">
    </div>
    <template>
      <main-navigation v-if="showNavigation && !error" />
      <v-main>
        <private />
        <snack-bars-list
          :items="getSnackBars"
        />
      </v-main>
    </template>
  </v-app>
</template>

<script>
import MainNavigation from '@/components/collections/MainNavigation/MainNavigation';
import Private from '@/views/Private';
import SnackBarsList from '@/components/ui/Snackbars/SnackBarsList';
import { mapGetters } from 'vuex';
// import cookie from 'vue-cookies';

export default {
  components: { SnackBarsList, Private, MainNavigation },
  data() {
    return {
      error: false,
    };
  },
  computed: {
    ...mapGetters({
      showNavigation: 'getAuthStatus',
      getSnackBars: 'getSnackBars',
      loader: 'getLoaderStatus',
      getSelectedWorkspace: 'getSelectedWorkspace',
      getSelectedTimerProject: 'getSelectedTimerProject',
    }),
  },
  watch: {
    $route(to) {
      this.error = to.name === 'Error404';

      if (this.getSnackBars.length) {
        this.$store.commit('clearSnackBars');
      }
    },
    showNavigation: {
      handler: 'fetchPermissions',
      immediate: true,
    },
  },

  mounted() {
    // window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    // window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    // handleBeforeUnload() {
    // const [navigation] = performance.getEntriesByType('navigation');
    //
    // if (navigation && navigation.type === 'reload') {
    //   // It's a page reload, so do NOT send the request
    //   console.log('Page is being reloaded, no request sent');
    // } else {
    //   // sessionStorage.setItem('isReload', 'false');
    //   console.log('User is navigating away, sending request...');
    // }
    // fetch(`${process.env.VUE_APP_BACKEND_URL}/activities/timer/stop`, {
    //   method: 'post',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${cookie.get('timeprismToken')}`,
    //   },
    //   body: JSON.stringify({
    //     project_id: this.getSelectedTimerProject.id,
    //     source: 'web',
    //   }),
    //   keepalive: true,
    // });
    // this.$store.commit('SET_TIMER_IS_RUNNING', false);
    // },
    fetchPermissions(val) {
      if (val && Object.keys(this.getSelectedWorkspace).length > 0) {
        this.$store.dispatch('fetchPermissions', this.getSelectedWorkspace.id);
      }

      if (val) {
        this.$store.dispatch('GET_UNREAD_COUNT');
      }
    },
  },
};
</script>
<style lang="scss">
  @import "@/sass/global.scss";
  .v-application {
    position: relative;
  }
  .v-navigation-drawer, .v-main{
    transition: none !important;
  }
  .loader {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: 1000;
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 33vw;
      height: 55vh;
    }
  }
  .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    border-color: transparent !important;
  }
</style>
